/**
 * Customer Portal API
 * See `api-description.md` for detailed description.
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Mandate purpose
 */
export type MandatePurpose = 'GENERAL' | 'ENERGY_SUPPLY_OFFER' | 'AGGREGATION_SERVICE' | 'AMPERFLOW_SOLAR_ENERGY_ANALYSIS' | 'AGGREGATION_OFFER' | 'ENERGIATURG_BILLING_AND_ANALYSIS' | 'CREDITINFO_ESG_REPORTING';

export const MandatePurpose = {
    General: 'GENERAL' as MandatePurpose,
    EnergySupplyOffer: 'ENERGY_SUPPLY_OFFER' as MandatePurpose,
    AggregationService: 'AGGREGATION_SERVICE' as MandatePurpose,
    AmperflowSolarEnergyAnalysis: 'AMPERFLOW_SOLAR_ENERGY_ANALYSIS' as MandatePurpose,
    AggregationOffer: 'AGGREGATION_OFFER' as MandatePurpose,
    EnergiaturgBillingAndAnalysis: 'ENERGIATURG_BILLING_AND_ANALYSIS' as MandatePurpose,
    CreditinfoEsgReporting: 'CREDITINFO_ESG_REPORTING' as MandatePurpose
};

