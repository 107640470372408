/* istanbul ignore file */
/* eslint-disable */

import { Injectable, Injector } from '@angular/core';
import { AlertService, ModalService, Utils } from '@elering/common';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { ErrorResponse } from '../models/error-response';
import { CustomAuthService } from '../overrides/custom-auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SessionExpiredModalComponent } from '../shared/session-expiry-timer/session-expired-modal.component';

@Injectable({
    providedIn: 'root'
})
export class ErrorResponseHandler {
    private authService: CustomAuthService;

    modalRef: BsModalRef;

    constructor(
        private alertService: AlertService,
        private translateService: TranslateService,
        private modalService: ModalService,
        private injector: Injector
    ) {
        setTimeout(() => {
            this.authService = this.injector.get(CustomAuthService);
        });
    }

    handleError(error: HttpErrorResponse): void {
        if (error instanceof HttpErrorResponse) {
            if (error.status === 401) {
                const wwwAuthenticate = error.headers.get('www-authenticate');
                if (wwwAuthenticate) {
                    const parsedWwwAuthenticate = this.parseWwwAuthenticate(wwwAuthenticate);
                    if (parsedWwwAuthenticate.get('error') === 'invalid_token') {
                        this.displaySessionExpiredDialog();
                        return;
                    }
                }
                this.alertService.showDangerToast(this.setError(error, 'general.error.sso'));
                setTimeout(() => this.authService.performLogout(), 5000);
            } else if (error.status === 500) {
                this.alertService.showDangerToast(this.setError(error, 'general.error.generic'));
            } else if (error.error.code) {
                this.handleCustomError(error);
            } else {
                this.handleGeneralError(error);
            }

            // Temporary solution;
            // Need to test if handleCustomError or handleGeneralError are even being used;
            // Potentially need to clean up error-handler;
            // TODO: Ask tester for every error that can be created and then test handling here.
        } else {
            console.error(error);
        }
    }

    handleGeneralError(response: HttpErrorResponse): void {
        if (response.error && response.error.messages) {
            let message = '';
            for (const subMessage of response.error.messages) {
                message += ` - ${subMessage}`;
            }
            this.alertService.showDangerToast(this.setError(response, message));
        } else {
            const errorMessage = response.error.message || 'Server error';
            this.alertService.showDangerToast(this.setError(response, errorMessage));
        }
    }

    handleCustomError(response: HttpErrorResponse): void {
        const customError: ErrorResponse = response.error;
        if (customError.code) {
            const message = this.getErrorTranslation(customError.code, []);
            if (message) {
                this.alertService.showDangerToast(this.setError(response, message));
                return;
            }
        }
        this.alertService.showDangerToast(this.setError(response, this.getErrorTranslation('error.generic')));
    }

    getErrorTranslation(errorKey: string, errorArgs?: string[]): string {
        const translationKey = `general.${errorKey}`;
        const translatedTxt = this.translateService.instant(translationKey);
        if (translatedTxt === translationKey) {
            return undefined;
        } if (errorArgs && errorArgs.length) {
            return Utils.formatString(translatedTxt, errorArgs);
        }
        return translatedTxt;
    }

    setError(err: HttpErrorResponse, message: string): string {
        const traceId = err.headers.get('X-Trace-Id')
        const id = traceId ?? err?.error?.id;
        const translatedMessage = this.translateService.instant(message);
        const errorWithId = `<b>${translatedMessage}</b><div>ID: ${id}</div>`;
        return id ? errorWithId : translatedMessage;
    }

    displaySessionExpiredDialog(): void {
        if (this.modalRef) {
            return;
        }
        const modalOptions: object = { backdrop: 'static', ignoreBackdropClick: true, class: 'modal-sm' };
        this.modalRef = this.modalService.showModal({
            content: SessionExpiredModalComponent,
            modalOptions: modalOptions,
            onHide: (reason) => {
                this.authService.performLogout();
                this.modalRef = undefined;
            }
        });
    }

    private parseWwwAuthenticate(header: string): Map<string, string> {
        return header.split(",")
            .map((e) => e.trim().split("="))
            .filter((e) => e.length > 1)
            .reduce(
                (result, keyValue) => {
                    result.set(keyValue[0], keyValue[1].split('"').join('') /* replaceAll */);
                    return result;
                },
                new Map<string, string>()
            );
    }
}

class ErrorSeverity {
    static WARNING = 'WARNING';
    static ERROR = 'ERROR';
}
